import React from 'react'

export const  MenuFooter = () => {
  return (
    <footer className="footer-07">
        <div className="container">
            <div className="row justify-content-center">
                <div className="col-md-12 text-center">
                    <h2 className="footer-heading"><a href="https://ollamenu.com/" className="logo">OllaMenu.com</a></h2>
                    <p className="menu">
                        Digital QR code menu service provider.
                    </p>
                </div>
            </div>
            <div className="row mt-5">
                <div className="col-md-12 text-center">
                    <p className="copyright">
                    Copyright ©2023 All rights reserved | service provided<i className="ion-ios-heart" aria-hidden="true"></i> by <a href="https://ollavibes.com" target="_blank">ollavibes.com</a>
                    </p>
                </div>
            </div>
        </div>
    </footer>
  )
}
