import React from 'react';
import { Header } from '../components';
import frontImage from './../assets/img/flayer.png';
import imageOne from './../assets/img/image1.jpg';
import imageTwo from './../assets/img/image2.jpeg';
import imageThree from './../assets/img/image3.jpg';
import clientOne from './../assets/img/mesob.jpg';
import clientTwo from './../assets/img/celavie.jpg';
import clientThree from './../assets/img/awash.jpg';
import clientFour from './../assets/img/letena.jpg';
import sampleQR from './../assets/img/qr-sample.jpg';
import './../assets/css/landing-page.css'

export const Home = () => {

  return (
    <>
        <Header />
      <section className='section-header pb-7 pb-lg-11 bg-soft'>
        <div className="container header-space">
          <div className="row justify-content-between align-items-center">
              <div className="col-12 col-md-6 order-2 order-lg-1">
              <img src={frontImage} alt="" style={{maxWidth: '100%'}}/>
              </div>
              <div className="col-12 col-md-5 order-1 order-lg-2">
                  <h1 className="display-2 mb-3 ckedit" key="contactles_menu" id="contactles_menu" contenteditable="false">Digital Restaurant &nbsp;Menu</h1>
                  <p className="lead ckedit" key="hero_title" id="hero_title" contenteditable="false">Create a digital menu for your Restaurant or Café. Engage more with your customers.</p>
                  <p className="lead ckedit" key="hero_subtitle" id="hero_subtitle" contenteditable="false"><strong> Our mobile's are your menu now, Call &nbsp;0910990417 &nbsp;for Information.</strong></p>
              
                
                    <div className="mt-4">
                    </div>
                </div>
          </div>
        </div>
        <div className="pattern bottom"></div>
      </section>

      <section id="product" className="section section-lg">
            <div className="container">
                <div className="row justify-content-center mb-5 mb-md-7">
                    <div className="col-12 col-md-8 text-center">
                    <i className="fas fa-edit mr-2 text-primary ckedit_btn" type="button" style={{display: 'none'}}></i> <h2 className="h1 font-weight-bolder mb-4 ckedit" key="product_title" id="product_title" contenteditable="false">The most comprehensive platform for QR digital menu in Addis</h2>
                    <i className="fas fa-edit mr-2 text-primary ckedit_btn" type="button" style={{display: 'none'}}></i> <p className="lead ckedit" key="product_description" id="product_description" contenteditable="false">There are platforms where you can make QR code, but no menu. There are platforms where you can create a menu but not a digital QR menu</p>
                    <i className="fas fa-edit mr-2 text-primary ckedit_btn" type="button" style={{display: 'none'}}></i> <p className="lead ckedit" key="product_subtitle" id="product_subtitle" contenteditable="false"><strong>We do both.&nbsp;.</strong></p>
                    </div>
                </div>
        <div className="row">
          <div className="col-12 col-md-6 col-lg-4 mb-5">
            <div className="card shadow-soft border-light">
                <div className="card-header p-0">
                <img src={imageOne} className="card-img-top rounded-top" alt="image" />
                </div>
                <div className="card-body">
                <i className="fas fa-edit mr-2 text-primary ckedit_btn" type="button" style={{display: 'none'}}></i> <h3 className="card-title mt-3 ckedit" id="product1_title" key="product1_title" contenteditable="false">Create a Digital Menu</h3>
                <i className="fas fa-edit mr-2 text-primary ckedit_btn" type="button" style={{display: 'none'}}></i> <p className="card-text ckedit" id="product1_subtitle" key="product1_subtitle" contenteditable="false">Create your menu directly on our platform. Update anytime. Easy And Simple</p>
                    <ul className="list-group d-flex justify-content-center mb-4">
                                            <li className="list-group-item d-flex pl-0 pb-1">
                                <span className="mr-2"><i className="fas fa-check-circle text-success"></i></span>
                                <i className="fas fa-edit mr-2 text-primary ckedit_btn" type="button" style={{display: 'none'}}></i> <div className="ckedit" id="product1_feature1" key="product1_feature1" contenteditable="false">Add restaurant name</div>
                            </li>
                                            <li className="list-group-item d-flex pl-0 pb-1">
                                <span className="mr-2"><i className="fas fa-check-circle text-success"></i></span>
                                <i className="fas fa-edit mr-2 text-primary ckedit_btn" type="button" style={{display: 'none'}}></i> <div className="ckedit" id="product1_feature2" key="product1_feature2" contenteditable="false">add more details like opening ours</div>
                            </li>
                                            <li className="list-group-item d-flex pl-0 pb-1">
                                <span className="mr-2"><i className="fas fa-check-circle text-success"></i></span>
                                <i className="fas fa-edit mr-2 text-primary ckedit_btn" type="button" style={{display: 'none'}}></i> <div className="ckedit" id="product1_feature3" key="product1_feature3" contenteditable="false">fire up the QR codes</div>
                            </li>
                                    </ul>
                    <a href="#" className="btn btn-primary">Experience it</a>
                </div>
            </div>
        </div>

         <div className="col-12 col-md-6 col-lg-4 mb-5">
              <div className="card shadow-soft border-light">
                  <div className="card-header p-0">
                  <img src={imageTwo} className="card-img-top rounded-top" alt="image" />
                  </div>
                  <div className="card-body">
                  <i className="fas fa-edit mr-2 text-primary ckedit_btn" type="button" style={{display: 'none'}}></i> <h3 className="card-title mt-3 ckedit" id="product2_title" key="product2_title" contenteditable="false">Create QR</h3>
                  <i className="fas fa-edit mr-2 text-primary ckedit_btn" type="button" style={{display: 'none'}}></i> <p className="card-text ckedit" id="product2_subtitle" key="product2_subtitle" contenteditable="false">8 different designs. Unlimited color options. Choose QR and Flyer style.</p>
                      <ul className="list-group d-flex justify-content-center mb-4">
                                              <li className="list-group-item d-flex pl-0 pb-1">
                                  <span className="mr-2"><i className="fas fa-check-circle text-success"></i></span>
                                  <i className="fas fa-edit mr-2 text-primary ckedit_btn" type="button" style={{display: 'none'}}></i> <div className="ckedit" id="product2_feature1" key="product2_feature1" contenteditable="false">Add your menu items</div>
                              </li>
                                              <li className="list-group-item d-flex pl-0 pb-1">
                                  <span className="mr-2"><i className="fas fa-check-circle text-success"></i></span>
                                  <i className="fas fa-edit mr-2 text-primary ckedit_btn" type="button" style={{display: 'none'}}></i> <div className="ckedit" id="product2_feature2" key="product2_feature2" contenteditable="false">Add prices, update menu&amp; etc</div>
                              </li>
                                              <li className="list-group-item d-flex pl-0 pb-1">
                                  <span className="mr-2"><i className="fas fa-check-circle text-success"></i></span>
                                  <i className="fas fa-edit mr-2 text-primary ckedit_btn" type="button" style={{display: 'none'}}></i> <div className="ckedit" id="product2_feature3" key="product2_feature3" contenteditable="false">Printable templates available to download in user panel</div>
                              </li>
                                      </ul>
                      <a href="#" className="btn btn-primary">Design it</a>
                  </div>
              </div>
          </div>
        
          <div className="col-12 col-md-6 col-lg-4 mb-5">
              <div className="card shadow-soft border-light">
                  <div className="card-header p-0">
                  <img src={imageThree} className="card-img-top rounded-top" alt="image" />
                  </div>
                  <div className="card-body">
                  <i className="fas fa-edit mr-2 text-primary ckedit_btn" type="button" style={{display: 'none'}}></i> <h3 className="card-title mt-3 ckedit" id="product3_title" key="product3_title" contenteditable="false">Go Digital</h3>
                  <i className="fas fa-edit mr-2 text-primary ckedit_btn" type="button" style={{display: 'none'}}></i> <p className="card-text ckedit" id="product3_subtitle" key="product3_subtitle" contenteditable="false">Your visitors will use their mobile phone camera/scan app to access your menu.</p>
                      <ul className="list-group d-flex justify-content-center mb-4">
                                              <li className="list-group-item d-flex pl-0 pb-1">
                                  <span className="mr-2"><i className="fas fa-check-circle text-success"></i></span>
                                  <i className="fas fa-edit mr-2 text-primary ckedit_btn" type="button" style={{display: 'none'}}></i> <div className="ckedit" id="product3_feature1" key="product3_feature1" contenteditable="false">QR can be scaned with any scan app</div>
                              </li>
                                              <li className="list-group-item d-flex pl-0 pb-1">
                                  <span className="mr-2"><i className="fas fa-check-circle text-success"></i></span>
                                  <i className="fas fa-edit mr-2 text-primary ckedit_btn" type="button" style={{display: 'none'}}></i> <div className="ckedit" id="product3_feature2" key="product3_feature2" contenteditable="false">Super-fast PWA tech included, one click and install the mobile app</div>
                              </li>
                                              <li className="list-group-item d-flex pl-0 pb-1">
                                  <span className="mr-2"><i className="fas fa-check-circle text-success"></i></span>
                                  <i className="fas fa-edit mr-2 text-primary ckedit_btn" type="button" style={{display: 'none'}}></i> <div className="ckedit" id="product3_feature3" key="product3_feature3" contenteditable="false">View analytics of scans and orders</div>
                              </li>
                                      </ul>
                      <a href="#" className="btn btn-primary">Go Mobile</a>
                  </div>
              </div>
          </div>
         </div>
        </div>
      </section>
      {/* Featured Clients */}
      <section id="testimonials" className="section-header bg-primary text-white">
        <div className="container">

            <div className="row justify-content-center mb-6">
                <div className="col-12 col-md-10 text-center">
                    <i className="fas fa-edit mr-2 text-white ckedit_btn" type="button" style={{display: 'none'}}></i>
                    <h1 className="display-2 mb-3 ckedit" key="featured_clients" id="featured_clients" contenteditable="false">
                        Our Clients</h1>
                    <i className="fas fa-edit mr-2 text-white ckedit_btn" type="button" style={{display: 'none'}}></i>
                    <p className="lead px-5 ckedit" key="list_of_featured_clients" id="list_of_featured_clients" contenteditable="false">
                        Here is a list of some of our Top clients</p>
                </div>
            </div>

            <div className="row text-gray">
                   <div className="col-md-4 col-lg-3 mb-5">
                    <div className="card shadow-soft border-light">
                        <div className="card-header p-0">
                            <a href="#"><img src={clientOne} className="card-img-top rounded-top" alt="image" /></a>
                        </div>
                        <div className="card-body">
                            <h4 className="mb-3 text-black">Mesob</h4>
                            <p className="card-text">Serves ethiopian dishes</p>
                        </div>
                    </div>
                  </div>

                 <div className="col-md-4 col-lg-3 mb-5">
                    <div className="card shadow-soft border-light">
                        <div className="card-header p-0">
                            <a href="#"><img src={clientTwo} className="card-img-top rounded-top" alt="image" /></a>
                        </div>
                        <div className="card-body">
                            <h4 className="mb-3 text-black">Celavie</h4>
                            <p className="card-text">Chicken and Burger</p>
                        </div>
                    </div>
                </div>

                <div className="col-md-4 col-lg-3 mb-5">
                    <div className="card shadow-soft border-light">
                        <div className="card-header p-0">
                            <a href="#"><img src={clientThree} className="card-img-top rounded-top" alt="image" /></a>
                        </div>
                        <div className="card-body">
                            <h4 className="mb-3 text-black">Awash</h4>
                            <p className="card-text">Ethiopian Restaurant located in US  </p>
                        </div>
                    </div>
                </div>
                        
                 <div className="col-md-4 col-lg-3 mb-5">
                    <div className="card shadow-soft border-light">
                        <div className="card-header p-0">
                            <a href="#"><img src={clientFour} className="card-img-top rounded-top" alt="image" /></a>
                        </div>
                        <div className="card-body">
                            <h4 className="mb-3 text-black">Letena</h4>
                            <p className="card-text">Cultural cousines </p>
                        </div>
                    </div>
                </div>



               <div style={{display: 'none'}}>

                   <div className="col-md-4 col-lg-3 mb-5">
                    <div className="card shadow-soft border-light">
                        <div className="card-header p-0">
                            <a href="https://mithaa.menu/restaurant/dcafe"><img src="https://mithaa.menu/uploads/restorants/bb04196c-4a96-45c3-bdf8-ac1c2f038d99_large.jpg" className="card-img-top rounded-top" alt="image" /></a>
                        </div>
                        <div className="card-body">
                            <h4 className="mb-3 text-black">D cafe</h4>
                            <p className="card-text">This is a Demo café</p>
                        </div>
                    </div>
                  </div>
                      
                  <div className="col-md-4 col-lg-3 mb-5">
                    <div className="card shadow-soft border-light">
                        <div className="card-header p-0">
                            <a href="https://mithaa.menu/restaurant/grovecafe"><img src="https://mithaa.menu/uploads/restorants/1c7c3cec-3dfd-4592-bdb1-d88f55a008c4_large.jpg" className="card-img-top rounded-top" alt="image" /></a>
                        </div>
                        <div className="card-body">
                            <h4 className="mb-3 text-black">Grove Café &amp; Bistro</h4>
                            <p className="card-text">Grove Cafe</p>
                        </div>
                    </div>
                </div>
                            
                  <div className="col-md-4 col-lg-3 mb-5">
                    <div className="card shadow-soft border-light">
                        <div className="card-header p-0">
                            <a href="https://mithaa.menu/restaurant/byyonicafe"><img src="https://mithaa.menu/uploads/restorants/9c955f0f-7bf2-46f4-8588-e7b36ccbb33b_large.jpg" className="card-img-top rounded-top" alt="image" /></a>
                        </div>
                        <div className="card-body">
                            <h4 className="mb-3 text-black">Byyoni Cafe</h4>
                            <p className="card-text">Byyoni cafe</p>
                        </div>
                    </div>
                </div>
                            
                            
                  <div className="col-md-4 col-lg-3 mb-5">
                    <div className="card shadow-soft border-light">
                        <div className="card-header p-0">
                            <a href="https://mithaa.menu/restaurant/aasispanipuri"><img src="https://mithaa.menu/uploads/restorants/f07b76b1-34f9-4b23-99de-6376be705d8c_large.jpg" className="card-img-top rounded-top" alt="image" /></a>
                        </div>
                        <div className="card-body">
                            <h4 className="mb-3 text-black">Aasi’s Pani Puri</h4>
                            <p className="card-text">Aasi’s Pani Puri</p>
                        </div>
                    </div>
                </div>  

             </div> 
                
          </div>
        </div>
     </section>  
    {/* Pricing  */}
    <div id="pricing" class="container">
      <div class="mt-5">
            <div class="d-style btn btn-brc-tp border-2 bgc-white btn-outline-blue btn-h-outline-blue btn-a-outline-blue w-100 my-2 py-3 shadow-sm">
              <div class="row align-items-center">
                <div class="col-12 col-md-4">
                  <h4 class="pt-3 text-170 text-600 text-primary-d1 letter-spacing">
                    Trial Plan
                  </h4>

                  <div class="text-secondary-d1 text-120">
                    Free for 6 months
                  </div>
                </div>

                <ul class="list-unstyled mb-0 col-12 col-md-4 text-dark-l1 text-90 text-left my-4 my-md-0">
                  <li>
                    <i class="fa fa-check text-success-m2 text-110 mr-2 mt-1"></i>
                    
                      <span class="text-110">Create a Digital Menu</span>
                  </li>

                  <li class="mt-25">
                    <i class="fa fa-check text-success-m2 text-110 mr-2 mt-1"></i>
                    <span class="text-110">
                     Create QR
                  </span>
                  </li>

                  {/* <li class="mt-25">
                    <i class="fa fa-times text-danger-m3 text-110 mr-25 mt-1"></i>
                    <span class="text-110">
                    Go Digital
                  </span>
                  </li> */}
                  <li class="mt-25">
                    <i class="fa fa-check text-success-m2 text-110 mr-2 mt-1"></i>
                    <span class="text-110">
                     Go Digital
                    </span>
                  </li>

                  <li class="mt-25">
                    <i class="fa fa-check text-success-m2 text-110 mr-2 mt-1"></i>
                    <span class="text-110">
                     Appear Nearby on our App
                  </span>
                  </li>

                </ul>

                <div class="col-12 col-md-4 text-center">
                  <a href="#" class="f-n-hover btn btn-info btn-raised px-4 py-25 w-75 text-600">Get Started</a>
                </div>
              </div>

            </div>



            <div class="d-style bgc-white btn btn-brc-tp btn-outline-green btn-h-outline-green btn-a-outline-green w-100 my-2 py-3 shadow-sm border-2">
              <div class="row align-items-center">
                <div class="col-12 col-md-4">
                  <h4 class="pt-3 text-170 text-600 text-green-d1 letter-spacing">
                    Paid Plan
                  </h4>

                  <div class="text-secondary-d2 text-120">
                    
                    <span class="align-text-bottom">Birr </span><span class="text-180">0</span> / month
                  </div>
                </div>

                <ul class="list-unstyled mb-0 col-12 col-md-4 text-dark-l1 text-90 text-left my-4 my-md-0">
                  <li>
                    <i class="fa fa-check text-success-m2 text-110 mr-2 mt-1"></i>
                      <span class="text-110">Create a Digital Menu</span>
                  </li>

                  <li class="mt-25">
                    <i class="fa fa-check text-success-m2 text-110 mr-2 mt-1"></i>
                    <span class="text-110">
                    Create QR
                  </span>
                  </li>

                  <li class="mt-25">
                    <i class="fa fa-check text-success-m2 text-110 mr-2 mt-1"></i>
                    <span class="text-110">
                    Go Digital
                  </span>
                  </li>

                  <li class="mt-25">
                    <i class="fa fa-check text-success-m2 text-110 mr-2 mt-1"></i>
                    <span class="text-110">
                     Appear Nearby on our App
                  </span>
                  </li>
                </ul>

                <div class="col-12 col-md-4 text-center">
                  <a href="#" class="f-n-hover btn btn-success btn-raised px-4 py-25 w-75 text-600">Get Started</a>
                </div>
              </div>

            </div>

          </div>
      </div>
    {/* Section Demo */}
    <section id="demo" className="section section-lg pb-5 bg-soft">
      <div className="container">
          <div className="row">
              <div className="col-12 text-center mb-5">
              <i className="fas fa-edit mr-2 text-primary ckedit_btn" type="button" style={{display: 'none'}}></i><h2 className="mb-4 ckedit" key="demo_title" id="demo_title" contenteditable="false">See a demo online menu</h2>
              <i className="fas fa-edit mr-2 text-primary ckedit_btn" type="button" style={{display: 'none'}}></i><p className="lead mb-5 ckedit" key="demo_subtitle" id="demo_subtitle" contenteditable="false">Just open the camera on your phone and scan the QR code below! <span className="font-weight-bolder">QR code</span> below!</p>
                  <a href="#" className="icon icon-lg text-gray mr-3">
                      <img style={{width:300, padding: 30, backgroundColor: 'white'}} src={sampleQR} />
                  </a>

              </div>
              <div className="col-12 text-center">
                  <a href="#" className="btn btn-secondary animate-up-2"><span className="mr-2"><i className="fas fa-hand-pointer"></i></span>Create a menu for you, now!</a>
              </div>
          </div>
          <br/>
      </div>
     </section>

     {/* Footer */}
      <footer className="footer section pt-6 pt-md-8 pt-lg-10 pb-3 bg-primary text-white overflow-hidden">
        <div className="pattern pattern-soft top"></div>
        <div className="container">
          <div className="row">
            <div className="col-lg-3 mb-4 mb-lg-0">
              <a href="#" className="icon icon-lg text-white mr-3 ">
                <h3>Olla Menu</h3>
              </a>

              <p className="my-4">Create a digital menu for your Restaurant or Café. Engage more with your customers.<br />Their mobile's are your menu now, Call &nbsp;0910990417 &nbsp;for Information.</p>

          </div>
          <div className="col-6 col-sm-3 col-lg-3 mb-4 mb-lg-0">
            <h6>Helpful Links</h6>
            <ul className="links-vertical">
            </ul>
          </div>

          <div className="col-6 col-sm-3 col-lg-3 mb-4 mb-lg-0">
            <h6>My Account</h6>
            <ul className="links-vertical">
              <li><a target="_blank" href="https://admin.ollavibes.com/login">

                Dashboard

              </a></li>
            </ul>
          </div>

          <div className="col-12 col-sm-6 col-lg-3">
          </div>


        </div>

        <div className="cookieConsentContainer js-cookie-consent cookie-consent" style={{display: 'block'}}>
          </div>
         <hr className="my-4 my-lg-5" />
          <div className="row">
            <div className="col pb-4 mb-md-0">
              <div className="d-flex text-center justify-content-center align-items-center">
                <p className="font-weight-normal mb-0">© <a href="https://ollamenu.menu" target="_blank">OllaMenu</a>
                  <span className="current-year"> 2023</span>. All rights reserved.
                </p>
              </div>
            </div>
          </div>
        </div>
      </footer>
    </>
  );
}
