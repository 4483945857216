// import './App.css';
// import './../src/assets/css/front.css'
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Link
} from "react-router-dom";
import { Menu } from './Pages/menu';
import { Home } from './Pages/home';

import {
  QueryClient,
  QueryClientProvider,
} from 'react-query';

const queryClient = new QueryClient();



function App() {
  return (
    <div className="App">
      <QueryClientProvider client={queryClient}>
        <Router>
            <div>
              {/* <ul>
                <li>
                  <Link to="/">Home</Link>
                </li>
                <li>
                  <Link to="/about">About</Link>
                </li>
              </ul>

              <hr /> */}

              {/*
                A <Switch> looks through all its children <Route>
                elements and renders the first one whose path
                matches the current URL. Use a <Switch> any time
                you have multiple routes, but you want only one
                of them to render at a time
              */}
              <Routes>
                <Route exact path="/" element={<Home />} />
                <Route exact path="/:id" element={<Menu />} />
                <Route path="/about" element={<Menu />} />
              </Routes>
            </div>
          </Router>
        </QueryClientProvider>
    </div>
  );
}

export default App;
