import React from 'react'
import logo from './../../assets/olla-menu-logo.png'

export const Header = () => {
  return (
    <header class="header-global bg-soft ">
     <nav id="navbar-main" className="navbar navbar-main navbar-expand-lg headroom py-lg-3 px-lg-6 navbar-light  headroom--not-bottom headroom--not-top">
        <div className="container">
            <a className="navbar-brand @logo_classNamees" href="/">
                {/* <img className="navbar-brand-dark common" src="/uploads/settings/04e318db-2ecc-4f6d-9b51-e46c35df4754_site_logo_dark.jpg" height="35" alt="Logo"> </img>*/}
                <img className="navbar-brand-light common" src={logo} height="35" alt="Logo"></img> 
            </a>
            <div className="navbar-collapse collapse" id="navbar_global">
                <div className="navbar-collapse-header">
                    <div className="row">
                        <div className="col-6 collapse-brand">
                            <a href="/">
                                {/* <img src="/uploads/settings/05979259-f8df-453f-b103-cb9ef085a37b_logo.jpg" height="35" alt="Logo"></img> */}
                            </a>
                        </div>
                        <div className="col-6 collapse-close">
                            <a href="#navbar_global" role="button" className="fas fa-times" data-toggle="collapse" data-target="#navbar_global" aria-controls="navbar_global" aria-expanded="false" aria-label="Toggle navigation"></a>
                        </div>
                    </div>
                </div>
                <ul className="navbar-nav navbar-nav-hover justify-content-center">
                    <li className="nav-item" data-toggle="collapse" data-target=".navbar-collapse.show">
                        <a data-scroll="" href="#product" className="nav-link">Product</a>
                    </li>
                    <li className="nav-item" data-toggle="collapse" data-target=".navbar-collapse.show">
                        <a data-scroll="" href="#pricing" className="nav-link">Pricing</a>
                    </li>
                    <li className="nav-item" data-toggle="collapse" data-target=".navbar-collapse.show">
                        <a data-scroll="" href="#testimonials" className="nav-link">Testimonials</a>
                    </li>
                    <li className="nav-item" data-toggle="collapse" data-target=".navbar-collapse.show">
                        <a data-scroll="" href="#demo" className="nav-link">Demo</a>
                    </li>
                 </ul>
            </div>
            <div className=" @cta_button_classNamees">
                <a data-scroll="" href="https://admin.ollavibes.com/login" className="btn btn-md btn-docs btn-white animate-up-2 mr-3">
                    <i className="fas fa-lock mr-2"></i>
                   Dashboard
                 </a>  
            </div>
            <div className="d-flex d-lg-none align-items-center">
                <button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbar_global" aria-controls="navbar_global" aria-expanded="false" aria-label="Toggle navigation"><span className="navbar-toggler-icon"></span></button>
            </div>
        </div>
     </nav>
    </header>
  )
}
